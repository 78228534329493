import { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useCupidoLogin } from '../../helpers/hooks';

export default function IplsLandingPage() {

  const isAuthenticated = useIsAuthenticated();
  const { instance, handleLogin, inProgress } = useCupidoLogin();

  useEffect(() => {
    if (isAuthenticated) return;
    if (inProgress !== 'none') return;

    handleLogin(instance);
  }, [instance, handleLogin, isAuthenticated, inProgress]);

  return null;
}
