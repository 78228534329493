export default {
  'What is Nucleo?': 'What is IPLS?',
  'Corporation': 'IPLS Group',
  'Module_Corporate_Team_Title': 'Board',
  'Delete_Corporation_Message': 'All data associated with the {{corporationName}} IPLS Group will be permanently deleted,'
      + ' including members\' accounts and their respective data, with no possibility of retrieval.',
  'Delete_Corporation_Member_Message': 'When {{name}} member is removed, their account and all its data will be permanently deleted'
    + ' from the platform, with no possibility of retrieval.',

  // used for testing, refactor in future so custom locales can be passed to useBrandingLocale()
  'Unit_Test_Key': 'String {{keyOne}} test {{keyTwo}} for {{keyOne}}',
};
